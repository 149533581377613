import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { PreviousRouteService } from 'src/app/_services/route-back.service';
import { SubscriptionPlanService } from 'src/app/_services/subscriptionplan.service'

@Component({
  templateUrl: './my-subscription.page.html',
  styleUrls: ['./my-subscription.page.scss']
})
export class MySubscriptionPage implements OnInit {

  isSubscribed: boolean;
  //subscriptionAmount: any;
  IsRecurring: any = 0;
  subscribtionList: any;
  subscribtionDetailList: any = {};
  subscriptionPlanId: any;
  user: any;
  renewDate : any;

  constructor(private router: Router, private authService: AuthService, private subscriptionPlanService: SubscriptionPlanService, public previousRouteService: PreviousRouteService) { }

  ngOnInit(): void {
    this.user = this.authService.currentUser;
    this.authService.onCurrentUserChange.subscribe(user => {
      this.user = user;
    });
    this.getAllSubscriptionPlan();
    this.getAllSubscriptionPlanDetails();
  }

  toggleSubscribed() {
    this.isSubscribed = !this.isSubscribed;
  }

  gotoProfilePage() {
    this.router.navigate(['/patient/profile']);
  }

  gotoSubscriptionInfoPage() {
    this.router.navigate(['/patient/subscription-info']);
  }
  goToSubscriptionHistoryPage() {
    this.router.navigate(['/patient/subscription-history']);
  }

  // getSubscriptionType(event) {
  //   this.subscriptionAmount = event.detail.value;
  //   console.log('type', event.detail.value)
  // }

  getSubscriptionPlanId(id) {
    this.subscriptionPlanId = id;
  }

  gotoPaySubscriptionPage() {
    let navigationExtras: NavigationExtras = {
      state: {
        // subscriptionAmount: this.subscriptionAmount,
        // subscriptionType: this.subscriptionAmount == 199 ? 'yearly' : 'monthly',
        IsRecurring: this.IsRecurring,
        subPlanId: this.subscriptionPlanId
      }
    };
    this.router.navigate(['/patient/pay-subscription'], navigationExtras);
  }

  isRecurring(event) {
    event.target.checked ? this.IsRecurring = 1 : this.IsRecurring = 0;
  }

  // createSubscriptionPlan() {
  //   const payload = {
  //     price: '19',
  //     stripePriceId: 'price_1IW0Z3CnsnykR0gbDMjee0YR',
  //     planType: 'Monthly',
  //     planName: '1 Month',
  //     status: 1     
  //   }
  //   this.subscriptionPlanService.createSubscriptionPlan(payload)
  //     .subscribe(resp => {
  //       console.log(`message: ${resp.message}`);        
  //       if (resp.errorcode == 1) {
  //         console.log(`message: ${resp.message}`);
  //       }
  //       if (resp.errorcode == 0) {
  //         if (resp.resultObj.Items) {

  //         }
  //       }
  //     });
  // }

  getAllSubscriptionPlan() {
    this.subscriptionPlanService.getAllSubscriptionPlan().subscribe(resp => {
      if (resp.errorcode == 1) {
        console.log(`message: ${resp.message}`);
      }
      if (resp.errorcode == 0) {
        if (resp.resultObj) {
          this.subscribtionList = resp.resultObj.reverse();
          this.subscriptionPlanId = this.subscribtionList[0].id;
        }
      }
    });
  }

  getAllSubscriptionPlanDetails() {
    this.subscriptionPlanService.getAllSubscriptionPlanDetail().subscribe(res => {
      if (res.errorcode === 0) {
        this.subscribtionDetailList = res.resultObj;
        let expDate = new Date(this.subscribtionDetailList.expiryDate)
        this.renewDate = expDate.setDate(expDate.getDate() + 1);
      }
    });
  }
}
