import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../_services/auth.service';
import { SubscriptionService } from '../../../../_services/subscription.service';
import { SubscriptionPlanService } from 'src/app/_services/subscriptionplan.service'
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { UserService } from 'src/app/_services/user.service';
import {ProfileService} from 'src/app/_services/profile.service';
import { PreviousRouteService } from 'src/app/_services/route-back.service';
import { SelectLocationPage } from '../../../shared/select-location/select-location.page';
// import { AlertController, ModalController } from '@ionic/angular';
@Component({
  templateUrl: './pay-subscription.page.html',
  styleUrls: ['./pay-subscription.page.css']
})
export class PaySubscriptionPage implements OnInit {

  isSubscribed: boolean;
  priceId = 'price_1HSxpTFHabj9XRH6DMA8pC7l';
  quantity = 1;
  currentUser: any;
  initiatePayment: boolean = true;
  paymentSuccess: boolean = false;
  subscriptionType: string;
  subscriptionAmount: number;
  IsRecurring: boolean = false;
  subPlanId: any;
  subscribtionPlan: any;
  customStripeForm: FormGroup;
  //submitted: boolean = false;
  formProcess: boolean = false;
  message: string;
  alertSuccessMessage: string;
  alertErrorMessage: string;
  alertError: boolean = false;
  alertSuccess: boolean = false;
  errorClass: any;
  monthArray = [];
  yearArray = [];
  userData: any;
  address: any;
  lat: any;
  lng: any;
  postalcode: any; 

  constructor(private router: Router, private authService: AuthService,
    private subscriptionService: SubscriptionService, private route: ActivatedRoute,
    private subscriptionPlanService: SubscriptionPlanService, private formBuilder: FormBuilder,
    private alertController: AlertController, private modalController: ModalController, private changeDetectorRef: ChangeDetectorRef, private userService: UserService,
    private profileService: ProfileService, public previousRouteService: PreviousRouteService) {
    this.customStripeForm = this.formBuilder.group({
      cardNumber: ['', Validators.required],
      expMonth: ['', Validators.required],
      expYear: ['', Validators.required],
      cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      address: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.getUserById(this.currentUser.userId);

    for (let i = 1; i <= 12; i++) {
      let num = i + "";
      if (num.length < 2) num = "0" + num;
      this.monthArray.push({ key: num, value: num });
    }
    for (let i = 0; i < 40; i++) {
      const currentYear = new Date().getFullYear().toString();
      const yearOption = parseInt(currentYear) + parseInt(i.toString());
      this.yearArray.push({ key: yearOption, value: yearOption });
    }

    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        // this.subscriptionAmount = this.router.getCurrentNavigation().extras.state.subscriptionAmount || 19;
        // this.subscriptionType = this.router.getCurrentNavigation().extras.state.subscriptionType || 'monthly'
        this.IsRecurring = this.router.getCurrentNavigation().extras.state.IsRecurring;
        this.subPlanId = this.router.getCurrentNavigation().extras.state.subPlanId;
        //redirect on subscription if no plan id 
        this.getSubscriptionPlanById(this.subPlanId);
      }
    });

    if (!this.subPlanId) {
      this.gotoSubscriptionPage();
    }

    this.loadStripe();
    //console.log('current user: ', this.currentUser);
  }

  toggleSubscribed() {
    this.isSubscribed = !this.isSubscribed;
  }

  getUserById(userId) {
    this.profileService.getUserProfile(userId)
      .subscribe(resp => {
        console.log('resp---', resp)
        if (resp.errorcode == 0) {
          this.userData = resp.resultObj;
          if (this.userData) {
            this.customStripeForm = this.formBuilder.group({
              cardNumber: ['', Validators.required],
              expMonth: ['', Validators.required],
              expYear: ['', Validators.required],
              cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
              address: [this.userData?.addressLine1, [Validators.required]],
              country: [this.userData?.country, Validators.required],
              state: [this.userData?.state, Validators.required],
              city: [this.userData?.city, Validators.required],
              zipCode: [this.userData?.zipCode, Validators.required]
            });
          }
        }
      });
  }

  gotoProfilePage() {
    this.router.navigate(['/patient/profile']);
  }

  gotoSubscriptionPage() {
    this.router.navigate(['/patient/my-subscription']);
  }

  gotoPaySubscriptionPage() {
    this.router.navigate(['/patient/pay-subscription']);
  }

  // async checkout(amount) {
  //   var handler = (<any>window).StripeCheckout.configure({
  //     key: environment.stripe_key,
  //     locale: 'auto',
  //     email: this.currentUser.email,
  //     token: (token: any) => {
  //       const that = this;
  //       console.log('token----', this.currentUser);
  //       // You can access the token ID with `token.id`.
  //       // Get the token ID to your server-side code for use.
  //       if (token && token.id) {
  //         this.initiatePayment = false;
  //         this.paymentSuccess = true;

  //         const payload = {
  //           patientId: this.currentUser.userId,
  //           transactionId: token.id
  //         }
  //         const subscriptionPayload = {
  //           type: this.subscriptionType,
  //           patientId: this.currentUser.userId,
  //           transactionId: token.id,
  //           amount: this.subscriptionAmount
  //         }
  //         console.log('subscriptionPayload---', subscriptionPayload);

  //         this.subscriptionService.createPatientSubscription(subscriptionPayload)
  //           .subscribe(resp => {
  //             console.log('response---', resp);
  //           })
  //       }
  //     }
  //   });

  //   handler.open({
  //     name: 'DocShowApp',
  //     description: 'Monthly Subscription',
  //     amount: amount * 100
  //   });
  // }

  // loadStripe1() {
  //   if (!window.document.getElementById('stripe-script')) {
  //     var s = window.document.createElement("script");
  //     s.id = "stripe-script";
  //     s.type = "text/javascript";
  //     s.src = "https://checkout.stripe.com/checkout.js";
  //     window.document.body.appendChild(s);
  //   }
  // }

  loadStripe() {
    if (!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        //window['Stripe'].setPublishableKey('pk_test_51IUC6DCnsnykR0gbVg3i01eKhHHcdUnbMulfD5M2w712h58LlSjwssvX9l2wMfq6Yu1CYZi4fXN7KlhfIXXMUM3T00OJYi1dTF');
        window['Stripe'].setPublishableKey('pk_test_51IJrxgKWtiNi7bZvn2xqrTZUxz6B4Gep0H6fga7AzXiYs9SblXD1NCBJsNnbp86TSHPHIFiiTS3C7VRkFztqaZzj00o4mTGM3G');
      }
      window.document.body.appendChild(s);

      // (<any>window).Stripe.card.addEventListener('change', event => {
      //   var displayError = document.getElementById('card-errors');
      //   if (event.error) {
      //     displayError.textContent = event.error.message;
      //   } else {
      //     displayError.textContent = '';
      //   }
      //   console.log('displayError', displayError);
      // });

    }
  }

  getSubscriptionPlanById(id) {
    this.subscriptionPlanService.getSubscriptionPlanById(id).subscribe(resp => {
      if (resp.errorcode == 0) {
        if (resp.resultObj && resp.resultObj) {
          this.subscribtionPlan = resp.resultObj;
          this.subscriptionAmount = this.subscribtionPlan.price;
          this.subscriptionType = this.subscribtionPlan.planType.toLowerCase();
        } else {
          this.gotoSubscriptionPage();
        }
      } else {
        console.log(`message: ${resp.message}`);
        this.gotoSubscriptionPage();
      }
    });
  }

  pay(form) {
    if (!this.validateCardDetails(form)) {
      setTimeout(() => {
        this.hideErrMsg();
      }, 2000);
      return;
    }

    if (!window['Stripe']) {
      this.alertError = true;
      this.alertErrorMessage = 'Payment did not initialize properly.';
      setTimeout(() => {
        this.hideErrMsg();
      }, 2000);
      return;
    }
    //this.submitted = true;
    //console.log(this.customStripeForm);
    if (this.customStripeForm.invalid) {
      return;
    }

    this.formProcess = true;
    (<any>window).Stripe.card.createToken({
      number: form.cardNumber,
      exp_month: form.expMonth,
      exp_year: form.expYear,
      cvc: form.cvv
    }, (status: number, response: any) => {
      //this.submitted = false;      
      if (status === 200) {
        //this.message = `Success! Card token ${response.card.id}.`;
        const payload = {
          number: form.cardNumber,
          exp_month: form.expMonth,
          exp_year: form.expYear,
          cvv: form.cvv,
          address: form.address,
          country: form.country,
          state: form.state,
          city: form.city,
          zipCode: form.zipCode,
          //token: response.card.id,
          token: response.id,
          amount: this.subscribtionPlan.price,
          type: this.subscribtionPlan.planType.toLowerCase(),
          isRecurring: this.IsRecurring,
          subscribtionPlanId: this.subscribtionPlan.Id,
          subscribtionPlanName: this.subscribtionPlan.planName,
          stripePriceId: this.IsRecurring ? this.subscribtionPlan.subscription_priceId : this.subscribtionPlan.stripePriceId
        }
        this.subscriptionPlanService.paySubscriptionPlan(payload).subscribe(resp => {
          console.log('paySubscriptionPlan api res: ', resp);
          if (resp.errorcode == 0) {
            this.initiatePayment = false;
            this.paymentSuccess = true;
            this.alertSuccess = true;
            this.alertSuccessMessage = `Payment successfully`;
          }
          else {
            this.alertError = true;
            this.alertErrorMessage = resp.message; //'something went wrong. Please try again.';
            setTimeout(() => {
              this.hideErrMsg();
            }, 2000);
          }
          this.formProcess = false;
          this.changeDetectorRef.detectChanges();
        });
      } else {
        this.formProcess = false;
        //this.message = response.error.message;        
        this.alertError = true;
        this.alertErrorMessage = 'Something went wrong when creating the token. Please try again.';
        setTimeout(() => {
          this.hideErrMsg();
        }, 2000);
      }
    });
  }

  validateCardDetails(form) {
    //Check input fields
    if (form.cardNumber == '' && form.expMonth == '' && form.expYear == '' && form.cvv == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the card details.';
      return false;
    }
    if (form.cardNumber == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the card number.';
      return false;
    }
    if (form.expMonth == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the expiry month.';
      return false;
    }
    if (form.expYear == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the expiry year.';
      return false;
    }
    if (form.cvv == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the CVV number.';
      return false;
    }
    if (form.address == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the address.';
      return false;
    }
    if (form.country == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the country name.';
      return false;
    }
    if (form.state == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the state name.';
      return false;
    }
    if (form.city == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the city name.';
      return false;
    }
    if (form.zipCode == '') {
      this.alertError = true;
      this.alertErrorMessage = 'Please enter the zipCode.';
      return false;
    }

    //Check expiry date
    const month = form.expMonth;
    const year = form.expYear;
    const expiryDate = new Date(year + '-' + month + '-01');
    if (expiryDate < new Date()) {
      this.alertError = true;
      this.alertErrorMessage = 'Card expiry date is not valid.';
      return false;
    }

    // Check CVV number is valid or not
    if (form.cvv.length > 4) {
      this.alertError = true;
      this.alertErrorMessage = 'CVV number is not valid.';
      return false;
    }
    return true;
  }

  creditCardValidator(event) {
    let validCreditCard = false;
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (event.detail.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      validCreditCard = true;
    } else {
      validCreditCard = false;
    }
    if (!validCreditCard) {
      this.errorClass = 'error-border-bot';
    }
    else {
      this.errorClass = '';
    }
    return validCreditCard;
  }

  // showAlert(header, msg) {
  //   this.alertController.create({
  //     header: header,
  //     message: msg,
  //     buttons: ['OK']
  //   }).then(res => {
  //     res.present();
  //   });
  // }

  hideSccMsg() {
    this.alertSuccess = false;
  }
  hideErrMsg() {
    this.alertError = false;
  }


  onInputChanged(event: Event) {
    // Cast the event target to HTMLInputElement to access its value
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    // Check if the input value exceeds the maximum length (16 characters)
    if (inputValue.length > 16) {
      // If the input value is longer than 16 characters,
      // set the input value to the first 16 characters only.
      const truncatedValue = inputValue.slice(0, 16);
      // Update the input field's value with the truncated value.
      // This will prevent any further input beyond the 16 characters.
      inputElement.value = truncatedValue;
    }
  }

  onInputChangedCvv(event: Event) {
    // Cast the event target to HTMLInputElement to access its value
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    // Check if the input value exceeds the maximum length (16 characters)
    if (inputValue.length > 3) {
      // If the input value is longer than 16 characters,
      // set the input value to the first 16 characters only.
      const truncatedValue = inputValue.slice(0, 3);
      // Update the input field's value with the truncated value.
      // This will prevent any further input beyond the 16 characters.
      inputElement.value = truncatedValue;
    }
  }

  async getGioLocation() {
    const modal = await this.modalController.create({
      component: SelectLocationPage,
      cssClass: 'custom-modal',
      componentProps: {
        'fromLat': '',
        'fromLng': ''
      }
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {


        this.lat = data.data.lat;
        this.lng = data.data.lng;
        this.address =  data.data.address,
        this.customStripeForm.patchValue({
          address: data.data.address,
          // this.registerForm.get('physicianAddress').setValue(data.data.address);
          // physicianAddress: data.data.address,
          addressLine1: data.data.address,
          // addressLine2: data.data.addressLine2,
          city: data.data.city,
          state: data.data.state,
          country: data.data.country,
          zipCode: data.data.postalCode,
        })
      }
    });

    return await modal.present();
  }

}
