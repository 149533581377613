import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private _baseUrl = environment.API_URL;
  private _headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private _http: HttpClient
  ) { }

  // Get profile api
  public getProfile = userId => {
    let apiEndPoint = `/profile/get/?userid=${userId}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public getUserProfile = userId => {
    let apiEndPoint = `/profile/getuser/?userId=${userId}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }


  // Update profile api
  public updateProfile = payload => {
    let apiEndPoint = '/profile/update';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }
  
  // Update profile api
  public updateNotificationSetting = payload => {
    let apiEndPoint = '/profile/updateNotificationSetting';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }
  // Update profile api
  public updateProfileImage = payload => {
    let apiEndPoint = '/profile/updateprofileimage';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload);
  }

  // Change password api
  public changePassword(payload) {
    return this._http.post<any>(`${environment.API_URL}/profile/changepassword`, payload);
  }

  // Update patient creditcard api
  public updateCreditCard = payload => {
    let apiEndPoint = '/profile/updatecreditcard';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  // Update doctor weekly schedule api
  public updateWeeklySchedule = payload => {
    let apiEndPoint = '/profile/updateweeklyschedule';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  // public getPatientRewards(params) {
  //   let apiEndPoint = `/rewards/getpatientrewards?patientId=${params.patientId}&page=${params.page}`;
  //   return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  // }
  // public getallDocuments(params) {
  //   let apiEndPoint = `/documents/getall?user=${params.id}&page=${params.page}`;
  //   return this._http.get<any>(this._baseUrl + apiEndPoint , this._headers);
  // }
  public getallDocumentsByDate(params) {
    let apiEndPoint = `/documents/getall${params}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
  public getDocuments(params){
    let apiEndPoint = `/documents/getall${params}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint , this._headers);
  }
  public uploadDocument = payload => {
    let apiEndPoint = '/documents/create';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload);
  }
    // Update doctors activation api
    public assignPermissionToAdmin = payload => {
      let apiEndPoint = '/profile/updateadminpermission';
      return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
    }

      // Get profile api
  public getListOfLocations = userId => {
    let apiEndPoint = `/physicianlocation/locations/?userid=${userId}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }




}