import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';;
import { environment } from 'src/environments/environment';
import { HELPERS } from '../_helpers/helpers';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private _baseUrl = environment.API_URL;
  private _headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  public createPatientSubscription = payload => {
    let apiEndPoint = '/subscription/create';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public createStripeTransaction = payload => {
    let apiEndPoint = '/transaction/create';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  // public getPatientSubscription(params) {
  //   let apiEndPoint = `/subscription/getAllByUserId?userId=${params.userId}&page=${params.page}`;
  //   return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  // }
  public getPatientSubscription = (params) => {
    let apiEndPoint = `/subscription/getAllByUserId${params}`
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public GetSubscriptionPlansInfo = () => {
    let apiEndPoint = '/membershipplan/getpublishedplans';
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public getAllByUserId = (searchQuery) => {
    let apiEndPoint = '/patientTransaction/getAllByUserId';
    return this.http.get<any>(this._baseUrl + apiEndPoint + searchQuery, this._headers);
  }
  public getAllTransactionByUserId = (id) => {
    let apiEndPoint = '/patientTransaction/getAllByUserId';
    return this.http.get<any>(this._baseUrl + apiEndPoint + id , this._headers);
  }

  public getSubscriptionHistory = (params) => {
    let apiEndPoint = `/subscription/getAllByUserId?userId=${params.userId}&page=${params.page}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public generateNewPDF = (params) => {
    let apiEndPoint = `/patientTransaction/generatePdf?id=${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
    

}
