import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, MenuController } from '@ionic/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  templateUrl: './patient-layout.page.html',
  styleUrls: ['./patient-layout.page.scss']
})
export class PatientLayoutPage {

  menus: any = [];
  currentUser: any;
  selectedMenu: any;
  
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    public menuController: MenuController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.menus = event.translations.PATIENTMENUS;
      });

      this.translate.get(['PATIENTMENUS']).subscribe((data) => {
        this.menus = data.PATIENTMENUS;
      });

      this.authService.onCurrentUserChange.subscribe(user => {
        this.currentUser = user;
      });
    });
  }

  gotoProfile() {
    this.router.navigate(['patient/profile']);
  }
  gotoPatientAppointments() {
    this.router.navigate(['patient/appointments']);
  }
  gotoHome() {
    this.router.navigate(['/']);
  }
  signout() {
    this.menuController.close();
    this.authService.logout();
  }
  notification() {
    this.router.navigate(['patient/notification-list']);
  }

}
