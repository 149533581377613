import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CategoryService } from 'src/app/_services/category.service';
import { RewardService } from 'src/app/_services/reward.service';

@Component({
  templateUrl: './patient-feedback.page.html',
  styleUrls: ['./patient-feedback.page.scss']
})
export class PatientFeedbackPage implements OnInit {


  @Input() fromParent;
  @Input() id;

  @ViewChild('rating') rating: any;
  
  user: any;
  feedbackData: any;
  feedbackPayload: any = {};
  stars1: number[] = [1, 2, 3, 4, 5];
 
  selectedValue1: number;
 

  constructor(private router: Router, private modalController: ModalController, private authService: AuthService,
    private rewardService: RewardService, private route: ActivatedRoute, private categoryService: CategoryService) { }

  ngOnInit() {
    this.id;
    //this.getfeedback();
  }

  // getfeedback() {
  //   this.categoryService.getfeedback(this.id).subscribe(response => {
  //     if (response.errorcode == 0) {
  //       this.feedbackData = response.resultObj;
  //       this.feedbackPayload.feedbackText = this.feedbackData.feedbackText;
  //       this.selectedValue1 = this.feedbackData.feedbackRating;
       
  //     }
  //   });
  // }

  addFeedback() {
    let payload = {
      appointmentId: this.id,
      feedbackText: this.feedbackPayload.feedbackText,
      feedbackRating: this.feedbackPayload.feedbackRating,      
    }
    this.categoryService.addAppointmentFeedback(payload).subscribe(res => {
      if (res.errorcode == 0) {
        this.router.navigate(['patient/appointments']);
        this.modalController.dismiss();
      }
    });
  }

  getfeedbackRating(feedbackRating) {
    this.feedbackPayload.feedbackRating = feedbackRating;
    this.selectedValue1 = feedbackRating;
  }

 
  gotoHomePage() {
    this.router.navigate(['patient/appointments']);
  }

  closeModal() {
    this.modalController.dismiss();
  }


}
