import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './select-reward.page.html',
  styleUrls: ['./select-reward.page.css']
})
export class SelectRewardPage implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
