import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './terms-conditions.page.html',
  styleUrls: ['./terms-conditions.page.css']
})
export class TermsConditionsPage implements OnInit {

  title: string = 'Terms & Conditions';

  constructor(private modalController:ModalController) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
