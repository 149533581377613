import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.css']
})
export class PrivacyPolicyPage implements OnInit {

  title: string = 'Privacy Policy';

  constructor(private modalController:ModalController) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
