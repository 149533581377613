import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'ion2-calendar';
import { CalendarCommonModule as NgCalendarModule, DateAdapter } from 'angular-calendar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SimpleMaskModule } from 'ngx-ion-simple-mask';
import { MySubscriptionPage } from './pages/patient/subscription/my-subscription/my-subscription.page';
import { PaySubscriptionPage } from './pages/patient/subscription/pay-subscription/pay-subscription.page';
import { SubscriptionHistoryPage } from './pages/patient/subscription/subscription-history/subscription-history.page';
import { SubscriptionInfoPage } from './pages/patient/subscription/subscription-info/subscription-info.page';
import { SharedModule } from './pages/shared/components/shared.module';
import { FAQPage } from './pages/shared/faq/faq.page';
import { PatientLayoutPage } from './pages/shared/patient-layout/patient-layout.page';
import { PrivacyPolicyPage } from './pages/shared/privacy-policy/privacy-policy.page';
import { ProviderAvailabilityPage } from './pages/shared/provider-availability/provider-availability.page';
import { ProviderLayoutPage } from './pages/shared/provider-layout/provider-layout.page';
import { PhysicianLayoutPage } from './pages/shared/physician-layout/physician-layout.page';
import { SiteAdminLayoutPage } from './pages/shared/site-admin-layout/site-admin-layout.page';
import { SelectLocationPage } from './pages/shared/select-location/select-location.page';
import { SelectRewardPage } from './pages/shared/select-reward/select-reward.page';
import { SubscriptionPopupPage } from './pages/shared/subscription-popup/subscription-popup.page';
import { TermsConditionsPage } from './pages/shared/terms-conditions/terms-conditions.page';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BarRatingModule } from "ngx-bar-rating";
import { OpenReminderPage } from './pages/patient/notifications/open-reminder/open-reminder.page';
import { IonicRatingModule } from 'ionic-rating';
import { PatientFeedbackPage } from './pages/patient/patient-feedback/patient-feedback.page';
import { FaqPage } from './pages/shared/components/faq-page/faq-page.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { SupportPage } from './pages/shared/components/support/support.component';
import { OpenDescriptionPage } from './pages/provider/documents/open-description/open-description.page';



export const config = {
  apiKey: "AIzaSyC2GdCpGS3iPw013CaKrly0HQW88UfZWoQ",
  authDomain: "docshowapp.firebaseapp.com",
  databaseURL: "https://docshowapp.firebaseio.com",
  projectId: "docshowapp",
  storageBucket: "docshowapp.appspot.com",
  messagingSenderId: "243184064388",
  appId: "1:243184064388:web:1f9b98867255e433"
};
@NgModule({
  declarations: [
    AppComponent,
    PatientLayoutPage,
    ProviderLayoutPage,
    PhysicianLayoutPage,
    SiteAdminLayoutPage,
    SelectLocationPage,
    OpenReminderPage,
    OpenDescriptionPage,
    FaqPage,
    SupportPage,
    PatientFeedbackPage,
    ProviderAvailabilityPage,
    PrivacyPolicyPage,
    TermsConditionsPage,
    SelectRewardPage,
    MySubscriptionPage,
    SubscriptionHistoryPage,
    PaySubscriptionPage,
    SubscriptionInfoPage,
    SubscriptionPopupPage,
    FAQPage
  ],
  imports: [
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SimpleMaskModule,
    IonicRatingModule,
    //TooltipsModule,
    BarRatingModule,
    HttpClientModule,
    CalendarModule,
    SharedModule,
    AngularFireModule.initializeApp(config),
    AngularFireMessagingModule,
    NgCalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDHcmiduScZm3pGCDheCN9t61qZwZzGLH8',//'YOUR-API-KEY-HERE',
      libraries: ['places']
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
