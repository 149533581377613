import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { OpenReminderPage } from '../pages/patient/notifications/open-reminder/open-reminder.page';
import { AngularFireMessaging } from '@angular/fire/messaging';

interface User {
  userId: string;
  name:String;
  firstName: String;
  lastName: String;
  primaryPhone: String;
  email: String;
  userType: Number;
  token: String;
  profileImageUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authUserSub: BehaviorSubject<User>;

  constructor(private afMessaging: AngularFireMessaging, private http: HttpClient,private modalController : ModalController, private router: Router, private alertController: AlertController) {
    
    this.authUserSub = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('authUser')));
  }

  get onCurrentUserChange() {
    return this.authUserSub.asObservable();
  }

  get currentUser() {
    return this.authUserSub.value;
  }

  get isLoggedIn() {
    return this.authUserSub.value ? true : false;
  }

  login(email: string, password: string, deviceId: string, fcm_web_token_key : string, userType = 1) {

    //return this.http.post<any>(`${environment.API_URL}/users/login`, { email, password })
    return this.http.post<any>(`${environment.API_URL}/auth/login`, { email, password, deviceId, fcm_web_token_key})
      .pipe(map(resp => {
        if (resp.errorcode == 1) {
          this.handleError(resp.message)
        }
        if (resp.errorcode == 0) {      
          
          console.log(resp.resultObj);
          const user = {
            userId: resp.resultObj.userId,
            name: resp.resultObj.firstName + ' ' + resp.resultObj.lastName,
            firstName: resp.resultObj.firstName,
            lastName: resp.resultObj.lastName,
            primaryPhone: resp.resultObj.primaryPhone,
            email: resp.resultObj.email,
            userType: resp.resultObj.userType,
            token: resp.resultObj.token,
            profileImageUrl: resp.resultObj.profileImageUrl,
            totalReward: resp.resultObj.totalReward,
            isAdminAccess: resp.resultObj.isAdminAccess,
            superAdminId:resp.resultObj.superAdminId,
            IsAssociated:resp.resultObj.IsAssociated,            
            physicianName:resp.resultObj.physicianName,
            physicianEmail:resp.resultObj.physicianEmail,
            physicianPhoneNo:resp.resultObj.physicianPhoneNo,
            physicianAddress:resp.resultObj.physicianAddress,
            physicianRegistrationNumber:resp.resultObj.physicianRegistrationNumber,
            AdminId:resp.resultObj.AdminId,
            physicianInfo:resp.resultObj.physicianInfo,
            SubAdminId:resp.resultObj.SubAdminId
          }


          localStorage.setItem('authUser', JSON.stringify(user));
          this.authUserSub.next(user);
          console.log(resp.resultObj);
          
          

          return user;
        }
        return null;
      }));
  }

  validateLoginAPI() {
    this.validateLogin().
      subscribe(resp => {
       
        if (resp.errorcode == 0 && resp.resultObj.validUser !== true) {
          
          // localStorage.removeItem('authUser');
          // this.authUserSub.next(null);
          // this.router.navigate(['/login']);
          this.logout(true);
        }
      });
  }

  validateLogin() {   
    return this.http.post<any>(`${environment.API_URL}/auth/validatelogin`, { fcmToken:this.getFCMToken() });
  }

  logout(isSilent = false ) {

    if (Capacitor.isNativePlatform()) {
      console.log("platform", Capacitor.isNativePlatform);
      let apiUrl = `${environment.API_URL}/auth/logout`;
      
      this.http.post<any>(apiUrl, { isSilent})
        .subscribe(resp => {
          if (resp.errorcode == 0)
            this.handleLogout(isSilent);
        })
    } else {
      this.handleLogout(isSilent);
      // let fcm_web_token_key = localStorage.getItem('fcm_web_token_key');
      // let apiUrl = `${environment.API_URL}/auth/logout`;
      // this.http.post<any>(apiUrl, { isSilent, fcm_web_token_key })
      //   .subscribe(resp => {
      //     if (resp.errorcode == 0)
      //       this.handleLogout(isSilent);
      //   })
    }
  }

  async openModal(notificationObject) {
    
    // const modal = await this.modalController.create({
      
    //   component: OpenReminderPage,
    //   cssClass: 'custom-modal',
    //   componentProps: {
    //     'Title': notification.data.title,
    //     'Message': notification.data.message,

    //   }
    // }
    // );
    this.router.navigate(['/open-reminder', notificationObject]);
    //return await modal.present();
  }


  profileRefresh(userInfo) {
    const user = this.currentUser;
    console.log("userInfo", userInfo); 
    //let combineName = userInfo.firstName;
    if(userInfo.firstName){
      user.name = userInfo.firstName + ' ' + userInfo.lastName;
    }    
    user.profileImageUrl = userInfo.profileImageUrl;

    localStorage.setItem('authUser', JSON.stringify(user));
    this.authUserSub.next(user);
    // localStorage.setItem('authUser', JSON.stringify(userInfo));
    // this.authUserSub.next(user);   
    // JSON.parse(localStorage.getItem('authUser'));
    // console.log("authUser", user);    
  }

  setFCMToken(fbToken) {
    localStorage.setItem('fbToken', JSON.stringify(fbToken));
  }

  getFCMToken() {
    return JSON.parse(localStorage.getItem('fbToken'));
  }

  handleError(msg) {
    this.alertController.create({
      header: 'Login Failed!',
      message: msg,
      buttons: ['OK']
    }).then(res => {
      res.present();
    });
  }

  handleLogout(isSilent = false ) {
    if(isSilent ){
      localStorage.removeItem('authUser');
      this.authUserSub.next(null);
      this.router.navigate(['/login']);
      return;
    }
    this.alertController.create({
      header: 'Please Confirm',
      message: 'Are you sure you want to Log Out ?',
      buttons: [
        'Cancel',
        {
          text: 'Logout',
          handler: () => {
            
            let fcm_web_token_key = localStorage.getItem('fcm_web_token_key');
            let apiUrl = `${environment.API_URL}/auth/logout`;
            this.http.post<any>(apiUrl, { isSilent, fcm_web_token_key })
              .subscribe(resp => {
                if (resp.errorcode == 0)
                  localStorage.removeItem('authUser');
                  this.authUserSub.next(null);
                  this.router.navigate(['/login']);
              })
            // localStorage.setItem('fcm_web_token_key' , null);
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  tokenGenerate() {
    let fcmWebToken = localStorage.getItem('fcm_web_token_key');
      if (fcmWebToken == null) {
        this.afMessaging.requestToken.subscribe((token) => {
          localStorage.setItem('fcm_web_token_key', token);
        });
      }else{
        console.log("FCM web token already created ", fcmWebToken)
      }
  }
  // refreshToken(token: string) {
  //   return this.http.post(AUTH_API + 'refreshtoken', {
  //     refreshToken: token
  //   }, httpOptions);
  // }
}