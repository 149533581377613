import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = this.authService.currentUser;
        if (user) {
            if (user.userType == 1) {
                this.router.navigate(['/patient']);
            } else if (user.userType == 2) {
                this.router.navigate(['/provider']);
            }
            else if (user.userType == 4) {
                this.router.navigate(['/physician']);
            }
            else if (user.userType == 5 || user.userType == 6 ) {
                this.router.navigate(['/site-admin']);
            }
            

            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}