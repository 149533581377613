import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _baseUrl = environment.API_URL;
  private _headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  public getUser(userId) {
    return this.http.get<any>(`${environment.API_URL}/users/${userId}`);
  }
  public registerUser(payload) {
    console.log("registration data",payload);
    
    return this.http.post<any>(`${environment.API_URL}/auth/register`, payload);
  }
  // public getAdminList() {
  //   return this.http.post<any>(`${environment.API_URL}/auth/getusers`,"");
  // }
 
  public registerFromAdmin(payload) {
    return this.http.post<any>(`${environment.API_URL}/auth/createuser`, payload);
  }

  public registerAddAdmin(payload) {
    return this.http.post<any>(`${environment.API_URL}/auth/createadmin`, payload);
  }
  public getAdminList(params) {   
    let apiEndPoint = `/profile/getusers${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
  public getSiteAdminList(params) {   
    let apiEndPoint = `/profile/getsiteadmin${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
  public confirmUser(token) {
    return this.http.post<any>(`${environment.API_URL}/auth/confirmemail`, { token });
  }

  public physicianConfirmUser(token) {
    return this.http.post<any>(`${environment.API_URL}/auth/physicianconfirmemail`, { token });
  }

  public forgotPassword(email) {
    return this.http.post<any>(`${environment.API_URL}/auth/forgotpassword`, { email });
  }

  public resetPassword(payload) {
    return this.http.post<any>(`${environment.API_URL}/auth/resetpassword`, payload);
  }  

  public getFacilites() {
    return this.http.post<any>(`${environment.API_URL}/auth/getfacilities`,"");
  }
}
