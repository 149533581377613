import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './select-location.page.html',
  styleUrls: ['./select-location.page.scss']
})
export class SelectLocationPage implements OnInit {

  @Input() fromLat: any;
  @Input() fromLng: any;

  @Input() fromParent;

  title: string = 'Choose Location';
  lat: number;
  lng: number;
  zoom: number;
  address: string;
  postalCode: string;

  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;

  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, public modalController: ModalController) { }

  ngOnInit() {

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      //always inialize
      this.geoCoder = new google.maps.Geocoder;

      if (this.fromLat && this.fromLng) {
        this.lat = this.fromLat;
        this.lng = this.fromLng;
        this.zoom = 8;
        this.getAddress(this.lat, this.lng);
      } else {
        this.setCurrentLocation();
      }

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, { types: [] });
      autocomplete.setComponentRestrictions({country: ["us"] });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.lat, this.lng);
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  markerDragEnd($event) {
    
    this.lat = $event.latLng.lat();
    this.lng = $event.latLng.lng();
    this.getAddress(this.lat, this.lng);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({
      'location': { lat: latitude, lng: longitude }
    }, (results, status) => {
      
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;


          for (let i = 0; i < results[0].address_components.length; i++) {
            for (let j = 0; j < results[0].address_components[i].types.length; j++) {
              if (results[0].address_components[i].types[j] == "postal_code") {
                this.postalCode = results[0].address_components[i].long_name;
              }
              if (results[0].address_components[i].types[j] == "premise") {
                this.addressLine1 = results[0].address_components[i].long_name;
              }
              if (results[0].address_components[i].types[j] == "neighborhood") {
                if (this.addressLine1)
                  this.addressLine1 = this.addressLine1 + ', ' + results[0].address_components[i].long_name;
                else
                  this.addressLine1 = results[0].address_components[i].long_name;
              }

              if (results[0].address_components[i].types[j] == "street_number") {
                if (this.addressLine1)
                  this.addressLine1 = this.addressLine1 + ', ' + results[0].address_components[i].long_name;
                else
                  this.addressLine1 = results[0].address_components[i].long_name;
              }
              if (results[0].address_components[i].types[j] == "route") {
                if (this.addressLine1)
                  this.addressLine1 = this.addressLine1 + ', ' + results[0].address_components[i].long_name;
                else
                  this.addressLine1 = results[0].address_components[i].long_name;
              }

              if (results[0].address_components[i].types[j] == "sublocality_level_2") {
                this.addressLine2 = results[0].address_components[i].long_name;
              }

              if (results[0].address_components[i].types[j] == "sublocality_level_1") {
                if (this.addressLine2)
                  this.addressLine2 = this.addressLine2 + ', ' + results[0].address_components[i].long_name;
                else
                  this.addressLine2 = results[0].address_components[i].long_name;
              }

              if (results[0].address_components[i].types[j] == "administrative_area_level_1") {
                this.state = results[0].address_components[i].long_name;
              }
              if (results[0].address_components[i].types[j] == "administrative_area_level_2") {
                this.city = results[0].address_components[i].long_name;
              }
              if (results[0].address_components[i].types[j] == "country") {
                this.country = results[0].address_components[i].long_name;
              }
            }
          }

        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  // closeModal(sendData) { 
  //   this.activeModal.close(sendData); 
  // }

  onSelectLocation() {

    let addressLine1 = this.addressLine1 || '';
    let addressLine2 = this.addressLine2 || '';
    let city = this.city || '';
    let state = this.state || '';
    let country = this.country || '';


    const data = {
      address: this.address,
      lat: this.lat,
      lng: this.lng,
      postalCode: this.postalCode,

      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      country: country,
    }
    this.modalController.dismiss(data);
  }

  closeModal() {
    this.modalController.dismiss();
  }


}
