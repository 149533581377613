import { MapsAPILoader } from '@agm/core';
import { Route } from '@angular/compiler/src/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { Plugins } from '@capacitor/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './open-description.page.html',
  styleUrls: ['./open-description.page.scss']
})
export class OpenDescriptionPage implements OnInit {


  @Input() document;
 
  
  //public searchElementRef: ElementRef;

  constructor(private router: Router, private route: ActivatedRoute, private ngZone: NgZone, public modalController: ModalController) { }

  ngOnInit() {
   
  }


  closeModal() {
    this.modalController.dismiss();
  }



}
