import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { CategoryService } from 'src/app/_services/category.service';
import { SubscriptionService } from 'src/app/_services/subscription.service';

@Component({
  templateUrl: './subscription-info.page.html',
  styleUrls: ['./subscription-info.page.scss']
})
export class SubscriptionInfoPage implements OnInit {

  title = "Subscription Info";
  subscriptionInfoList: any[];
  membershipData: any = {};
  currentPlan = [];
  otherPlan = [];

  constructor(private subscriptionService: SubscriptionService, private categoryService: CategoryService ) { }

  ngOnInit(): void {
    this.getpatientplan();
    this.getSubscriptionMoreInfo();
   
  }
  getpatientplan() {
    this.categoryService.getpatientplan().subscribe(res => {
      if (res.errorcode === 0) {
        this.membershipData = res.resultObj;
        console.log("Membershipdata", this.membershipData);
      }
    });
  }
  getSubscriptionMoreInfo() {

    this.subscriptionService.GetSubscriptionPlansInfo()
      .subscribe(resp => {
        console.log('response---', resp);
        if (resp.errorcode == 0) {
         
            this.subscriptionInfoList = resp.resultObj;
            console.log(this.membershipData.planName);
          this.currentPlan = this.subscriptionInfoList.filter(m => m.id == this.membershipData.id);
          this.otherPlan = this.subscriptionInfoList.filter(m => m.id != this.membershipData.id);
          console.log("currentPlan", this.currentPlan )
          console.log("otherPlan", this.otherPlan )

            
        }
      });
    }

    getPlanBadge(planObj) {
      if (planObj.maxRewardPoints < 1000) {
        return `../../assets/images/badges/silver_membership.png`
      } else if (planObj.maxRewardPoints < 2000) {
        return `../../assets/images/badges/gold_membership.png`
      } else if (planObj.minRewardPoints == 2000 &&  planObj.maxRewardPoints >= 2000) {
        return `../../assets/images/badges/platinum.png`
      }
    }


  // gotoSubscriptionPage() {
  //   this.router.navigate(['patient/my-subscription']);
  // }
}
