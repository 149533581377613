import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthService } from 'src/app/_services/auth.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { RewardService } from 'src/app/_services/reward.service';
import {SubscriptionService} from '../../../../_services/subscription.service';

@Component({
  templateUrl: './subscription-history.page.html',
  styleUrls: ['./subscription-history.page.scss']
})
export class SubscriptionHistoryPage implements OnInit {
  subscriptionHistoryList: any = [];
  loadMoreCheck: false;
  nextPage: number;
  meta: any = {};
  user: any;
  searchObj: any = {};
  startDate: any = null;
  endDate: any = null;
  type: any = '';
  renew: any = '';
  amount: any = '';

  isListEmpty=true;

  constructor(
    private router: Router,
    private alertController: AlertController,
    public loading: LoadingService,
    private rewardService: RewardService,
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.currentUser;
    this.authService.onCurrentUserChange.subscribe(user => {
      this.user = user;
    });
    this.getSubscriptionHistory(false);
  }

  getSubscriptionHistory(isFilter) {
    if (isFilter){
      this.meta=null;
      this.subscriptionHistoryList = [];
    }
    let searchQuery = '';
    searchQuery += "?userId=" + this.authService.currentUser.userId;
    searchQuery += "&page=" + ((this.meta && this.meta['currentPage']) ? (this.meta['currentPage'] + 1) : 1);
    if (this.type) {
      searchQuery += "&type=" + this.type;
    }
    if (this.renew) {
      searchQuery += "&isRecurring=" + this.renew;
    }
    if (this.amount) {
      searchQuery += "&amount=" + this.amount;
    }
    if (this.startDate) {
      searchQuery += "&startDate=" + this.getDateFormat(this.startDate);
    }

    if (this.endDate) {
      searchQuery += "&endDate=" + this.getDateFormat(this.endDate);
    }

    let startDateFilter = new Date(this.startDate).toISOString();
    let endDateFilter = new Date(this.endDate).toISOString()
    if (startDateFilter > endDateFilter) {
      this.showDateAlert();
      return;
    }
    console.log('search----', searchQuery);
    this.loading.present().then(() => {
      this.subscriptionService.getPatientSubscription(searchQuery).subscribe(resp => {
        if (resp.errorcode === 0) {
          this.subscriptionHistoryList = [...this.subscriptionHistoryList, ...resp.resultObj.data];
          console.log(' list----', this.subscriptionHistoryList);
          this.meta = resp.resultObj.meta;        
        }
      });
    });
  }

  
  getDateFormat(datestring) {
    var d = new Date(datestring),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  showDateAlert() {
    this.alertController.create({
      header: 'Subscription History!',
      message: 'Invalid Date',
      buttons: ['OK']
    }).then(res => {
      res.present();
    });
  }

  doRefresh(event) {
    console.log('Begin async operation');
    this.getSubscriptionHistory(true);
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  resetFilter() {
   
      this.startDate = null;
      this.endDate = null;
      this.type = null;
      this.renew = null;
      this.meta = null;
    this.subscriptionHistoryList = [];
    this.getSubscriptionHistory(false);
   
  }
  
  loadData(event) {
    console.log('load data----', this.meta);
    if (this.subscriptionHistoryList && this.subscriptionHistoryList.length < this.meta['totalCount']) {
      setTimeout(() => {
        console.log('Done', this.subscriptionHistoryList.length);
        this.getSubscriptionHistory(false);
        event.target.complete();
      }, 2000);
    } else {
      event.target.disabled = true;
    }

  }

  changedDate() {
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.endDate = "";
    }
  }


    // this.subscriptionHistoryList =[];// this.rewardService.getSubscriptionHistory();

    // this.isListEmpty=!(this.subscriptionHistoryList && this.subscriptionHistoryList.length > 0);
  } 


